export const gtm = () => {
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-WX5B2N9');
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-M2P4VWM');

    const pricePage = document.querySelector('[data-prices]');

    if (pricePage) {
        const ecommerceItemOneMonth = {
            item_name: '1 month',
            item_id: 'cc_mf1_50',
            currency: pricePage.getAttribute('data-prices-currency'),
            price: getPriceNumber(pricePage.getAttribute('data-prices-one-month')),
            item_brand: 'Cocospy',
            item_list_name: 'Prices',
            item_list_id: '/prices.html',
            quantity: 1,
        };

        const ecommerceItemThreeMonths = {
            item_name: '3 months',
            item_id: 'cc_mf3_90',
            currency: pricePage.getAttribute('data-prices-currency'),
            price: getPriceNumber(pricePage.getAttribute('data-prices-three-month')),
            item_brand: 'Cocospy',
            item_list_name: 'Prices',
            item_list_id: '/prices.html',
            quantity: 1,
        };

        const ecommerceItemTwelveMonths = {
            item_name: '12 months',
            item_id: 'cc_mf12_150',
            currency: pricePage.getAttribute('data-prices-currency'),
            price: getPriceNumber(pricePage.getAttribute('data-prices-twelve-month')),
            item_brand: 'Cocospy',
            item_list_name: 'Prices',
            item_list_id: '/prices.html',
            quantity: 1,
        };

        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            event: 'view_item_list',
            ecommerce: {
                items: [ecommerceItemOneMonth, ecommerceItemThreeMonths, ecommerceItemTwelveMonths],
            },
        });

        function getPriceNumber(price) {
            let priceTypeNumber = price.replaceAll(',', '.');
            const priceParts = priceTypeNumber.split('.');

            if (priceParts.length === 3) {
                priceTypeNumber = priceTypeNumber.replace('.', '');
            }

            return Number(priceTypeNumber);
        }

        const planSubmits = document.querySelectorAll('[data-gtm-add-to-cart]');
        if (!planSubmits.length) return;

        [...planSubmits].forEach(function (submit) {
            submit.addEventListener('click', function () {
                const planMonth = this.dataset.gtmAddToCart;
                let itemGTM;

                if (planMonth === '1') {
                    itemGTM = ecommerceItemOneMonth;
                } else if (planMonth === '3') {
                    itemGTM = ecommerceItemThreeMonths;
                } else if (planMonth === '12') {
                    itemGTM = ecommerceItemTwelveMonths;
                }

                dataLayer.push({ ecommerce: null });

                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    event: 'add_to_cart',
                    ecommerce: {
                        purchase: {
                            items: [itemGTM],
                        },
                    },
                });
            });
        });
    }
};
